import React, { useEffect, useState } from "react";
import Spinner from "./Spinner";
import { toast } from "react-toastify";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { BASE_URL } from "../utils";

function Fund({ setFundModal }) {
  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;
  const [isLoading, setLoading] = useState(false);

  const { register, handleSubmit, setValue } = useForm();
  useEffect(() => {
    setValue("currency", "NGN");
  }, [setValue]);

  const submitdHandler = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post(`${BASE_URL}admin/fund`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      setLoading(false);
      const message = res.data.message;
      setFundModal(false);
      toast.success(message);
      // window.location.reload(false);
      console.log(message);
    } catch (err) {
      setLoading(false);
      toast.error(err?.message || err?.data.message);
    }
  };
  
  return (
    <form onSubmit={handleSubmit(submitdHandler)}>
      <div>
        <label
          for="email"
          className="text-left block text-sm text-gray-800 font-semibold py-2"
        >
          Email
        </label>
        <input
          type="email"
          name="email"
          required
          {...register("email")}
          placeholder="Email Address"
          className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
        />
      </div>
      <div>
        <label
          for="amount"
          className="text-left block text-sm text-gray-800 font-semibold py-2"
        >
          Amount
        </label>
        <input
          type="text"
          name="amount"
          required
          {...register("amount")}
          placeholder="Enter Amount"
          className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
        />
      </div>
      <div>
        <label
          for="currency"
          className="text-left block text-sm text-gray-800 font-semibold py-2"
        >
          Currency
        </label>
        <input
          type="text"
          name="currency"
          required
          {...register("currency")}
          className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
        />
      </div>

      {/* <div>
        <label
          for="currency"
          className="text-left block text-sm text-gray-800 font-semibold py-2"
        >
          Reference
        </label>
        <input
          type="text"
          name="reference"
          required
          {...register("reference")}
          className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
        />
      </div> */}

      <div className="mt-3">
        <button
          type="submit"
          disabled={isLoading}
          className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#284C84] rounded-sm hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
        >
          {isLoading ? <Spinner /> : "Fund User"}
        </button>
      </div>
    </form>
  );
}

export default Fund;
