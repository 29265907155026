import { apiOne, apiTwo } from "./apiSlice";

export const hotelAuthApiSlice = apiTwo.injectEndpoints({
  endpoints: (builder) => ({
    createHotelAdmin: builder.mutation({
      query: (data) => ({
        url: `hotel/registerMerchants`,
        method: "POST",
        body: data,
      }),
    }),
    createCharges: builder.mutation({
      query: (data) => ({
        url: `hotel/createCharges`,
        method: "POST",
        body: data,
      }),
    }),

    getAllHotelMerchants: builder.query({
      query: () => ({
        url: `hotel/hotelMerchants`,
        keepUnusedDataFor: 5,
      }),
    }),

    checkMyBookings: builder.query({
      query: () => ({
        url: `hotel/returnUserBookings`,
        keepUnusedDataFor: 5,
      }),
    }),

    bookHotel: builder.mutation({
      query: (data) => ({
        url: `hotel/bookHotel`,
        method: "POST",
        body: data,
      }),
    }),

    getHotelMerchant: builder.query({
      query: (id) => ({
        url: `hotel/hotelMerchant/${id}`,
        keepUnusedDataFor: 5,
      }),
    }),

    fetchMerchantBankDetailsForBillTop: builder.query({
      query: (id) => ({
        url: `hotel/fetchMerchantBankDetailsForBillTop/${id}`,
        keepUnusedDataFor: 5,
      }),
    }),
  }),
});

export const {
  useBookHotelMutation,
  useCheckMyBookingsQuery,
  useCreateHotelAdminMutation,
  useGetAllHotelMerchantsQuery,
  useGetHotelMerchantQuery,
  useCreateChargesMutation,
  useFetchMerchantBankDetailsForBillTopQuery,
} = hotelAuthApiSlice;
