import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import PageLoading from "../../../components/PageLoading";
import Spinner from "../../../components/Spinner";
import {
  useGetUserCryptoSellTransactionQuery,
  useGetUserSellGiftcardTransactionQuery,
} from "../../../Slices/transactionApsSlice";
import { BASE_URL } from "../../../utils";
import FormattedDate from "../../../components/FormattedDate";
import Search from "../../../components/Search";

const CryptoDenominationForm = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, reset } = useForm();
  const setDenominationHandler = async (data) => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${BASE_URL}admin/createCryptoDenomination`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const message = res.data.message;
      toast.success("Denomination Created");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.message || err?.data.message);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(setDenominationHandler)(e);
        reset();
      }}
      className="  transition-all duration-500 max-h-96 bg-[#FCFCFC]"
    >
      <div className="px-4 pb-4 flex gap-x-2">
        <div className="w-full">
          <label htmlFor="Denomination" className="text-gray-500 text-left">
            Create New Denomination
          </label>
          <input
            type="text"
            required
            {...register("denomination")}
            className="block text-xs mt-1 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>
        <button
          type="submit"
          className="mx-4 mt-4 font-semibold w-[14rem] h-[3rem] mb-5 transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#284C84]  px-3 py-2.5 rounded-lg "
        >
          {loading ? (
            <svg
              aria-hidden="true"
              role="status"
              className="inline mr-3 w-4 h-4 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              ></path>
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              ></path>
            </svg>
          ) : (
            "Create"
          )}
        </button>
      </div>
    </form>
  );
};

const GiftCardDenominationForm = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const setDenominationHandler = async (data) => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${BASE_URL}admin/createDenomination`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const message = res.data;
      toast.success("Denomination Created");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.message || err?.data.message);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(setDenominationHandler)(e);
        reset();
      }}
      className="  transition-all duration-500 max-h-96 bg-[#FCFCFC]"
    >
      <div className="px-4 pb-4 flex gap-x-2">
        <div className="w-full">
          <label htmlFor="Denomination" className="text-gray-500 text-left">
            Create New Denomination
          </label>
          <input
            type="text"
            required
            {...register("denomination")}
            className="block text-xs mt-1 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>
        <button
          type="submit"
          className="mx-4 mt-4 font-semibold w-[14rem] h-[3rem] mb-5 transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#284C84]  px-3 py-2.5 rounded-lg "
        >
          {loading ? (
            <svg
              aria-hidden="true"
              role="status"
              className="inline mr-3 w-4 h-4 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              ></path>
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              ></path>
            </svg>
          ) : (
            "Create"
          )}
        </button>
      </div>
    </form>
  );
};

const GiftForm = ({ id, setReFetch, countries, allGiftCardDenomination }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, setValue, reset } = useForm();
  const setGiftCardHandler = async (data) => {
    try {
      setLoading(true);
      const res = await axios.post(`${BASE_URL}admin/setRate`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      const message = res.data.message;
      toast.success(message);
      setReFetch(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.message || err?.data.message);
    }
  };
  useEffect(() => {
    setValue("giftcard_id", id); // Set default value for the 'age' field
  }, [setValue, id]);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(setGiftCardHandler)(e);
        reset();
      }}
      className="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96 bg-[#FCFCFC]"
    >
      <div className="w-full p-4 flex items-center gap-x-2">
        <input
          type="text"
          required
          {...register("giftcard_id")}
          className="hidden text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
        />
        <div className="w-full">
          <label htmlFor="SellingRate" className="block text-xs text-gray-500 ">
            Selling Rate
          </label>

          <input
            type="text"
            required
            {...register("selling_rate")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>

        <div className="w-full">
          <label htmlFor="BuyingRate" className="block text-xs text-gray-500 ">
            Buying Rate
          </label>

          <input
            type="text"
            required
            {...register("buying_rate")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>
      </div>

      <div className="px-4 pb-4 flex gap-x-2">
        <div className="w-full">
          <label htmlFor="SellingRate" className="block text-xs text-gray-500 ">
            Country
          </label>
          <div className="w-full ">
            <div className="relative">
              <select
                required
                {...register("country_id")}
                className="py-2 my-1 block appearance-none border text-[#0C0C0C] bg-[#FFF] rounded-lg w-full focus:outline-none px-5 text-sm"
              >
                <option>Select Country</option>
                {countries.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.countryName}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <label htmlFor="cardType" className="block text-xs text-gray-500 ">
            Card Type
          </label>
          <div className="w-full ">
            <div className="relative">
              <select
                required
                {...register("cardType")}
                className="py-2 my-1 block appearance-none border text-[#0C0C0C] bg-[#FFF] rounded-lg w-full focus:outline-none px-5 text-sm"
              >
                <option>Select CardType</option>
                <option value="Physical">Physical</option>
                <option value="Ecode">Ecode</option>
                <option value="Cash-Receipt">Cash receipt</option>
                <option value="Debit-Receipt">Debit Receipt</option>
                <option value="No-Receipt">No Receipt</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 pb-4 flex gap-x-2">
        {" "}
        <div className="w-full">
          <label
            htmlFor="Denomination"
            className="block text-xs text-gray-500 "
          >
            Denomination
          </label>
          <div className="w-full ">
            <div className="relative">
              <select
                required
                {...register("denomination_id")}
                className="py-2 my-1 block appearance-none border text-[#0C0C0C] bg-[#FFF] rounded-lg w-full focus:outline-none px-5 text-sm"
              >
                <option>Select Denomination</option>
                {allGiftCardDenomination.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.denomination}
                  </option>
                ))}
              </select>

              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="mx-4 mt-4 font-semibold w-[14rem] h-[2.5rem] mb-5 transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#284C84]  px-3 py-2.5 rounded-lg "
        >
          {loading ? (
            <svg
              aria-hidden="true"
              role="status"
              className="inline mr-3 w-4 h-4 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              ></path>
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              ></path>
            </svg>
          ) : (
            "Set"
          )}
        </button>
      </div>
    </form>
  );
};

const EditGiftForm = ({ id, setReFetch, details, allGiftCardDenomination }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("");
  const [cardType, setCardType] = useState("");

  const { register, handleSubmit, setValue, reset } = useForm();
  const setGiftCardHandler = async (data) => {
    try {
      setLoading(true);
      const res = await axios.put(`${BASE_URL}admin/updateRates/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      const message = res.data.message;
      toast.success(message);
      // setReFetch(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.message || err?.data.message);
    }
  };
  useEffect(() => {
    setValue("giftcard_id", id);
    setValue("selling_rate", details.selling_rate);
    setValue("buying_rate", details.buying_rate);
    setValue("cardType", details.cardType);
    setValue("denomination_id", details.denomination_id);
    setValue("country_id", details.country_id);
    setCountry(details.country);
    setCardType(details.cardType);
  }, [
    setValue,
    id,
    details.selling_rate,
    details.buying_rate,
    details.cardType,
    details.denomination,
    details.country_id,
    details.country,
  ]);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(setGiftCardHandler)(e);
        reset();
      }}
      className="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96 bg-[#FCFCFC]"
    >
      <div className="w-full p-4 flex items-center gap-x-2">
        <input
          type="text"
          required
          {...register("giftcard_id")}
          className="hidden text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
        />
        <div className="w-full">
          <label htmlFor="SellingRate" className="block text-xs text-gray-500 ">
            Selling Rate
          </label>

          <input
            type="text"
            required
            {...register("selling_rate")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>

        <div className="w-full">
          <label htmlFor="BuyingRate" className="block text-xs text-gray-500 ">
            Buying Rate
          </label>

          <input
            type="text"
            required
            {...register("buying_rate")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>
      </div>

      <div className="px-4 flex gap-x-2">
        <div className="w-full">
          <label htmlFor="SellingRate" className="block text-xs text-gray-500 ">
            Country
          </label>
          <input
            type="text"
            className="hidden"
            required
            {...register("country_id")}
          />
          <div className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 capitalize">
            {country}
          </div>
        </div>
        <div className="w-full">
          <label htmlFor="cardType" className="block text-xs text-gray-500 ">
            Card Type
          </label>

          <div className="w-full ">
            <div className="relative">
              <select
                required
                {...register("cardType")}
                className="py-2 my-1 block appearance-none border text-[#0C0C0C] bg-[#FFF] rounded-lg w-full focus:outline-none px-5 text-sm"
              >
                {/* <option>{cardType}</option> */}
                <option value="Physical">Physical</option>
                <option value="Ecode">Ecode</option>
                <option value="Cash-Receipt">Cash receipt</option>
                <option value="Debit-Receipt">Debit Receipt</option>
                <option value="No-Receipt">No Receipt</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-4 flex gap-x-2">
        <div className="w-full">
          <label
            htmlFor="Denomination"
            className="block text-xs text-gray-500 "
          >
            Denomination
          </label>
          <div className="w-full ">
            <div className="relative">
              <select
                required
                {...register("denomination_id")}
                className="py-2 my-1 block appearance-none border text-[#0C0C0C] bg-[#FFF] rounded-lg w-full focus:outline-none px-5 text-sm"
              >
                <option>Select Denomination</option>
                {allGiftCardDenomination.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.denomination}
                  </option>
                ))}
              </select>

              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="font-semibold w-[12rem] h-[2.5rem] mt-5 transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#284C84]  px-3 py-2.5 rounded-lg "
        >
          {loading ? (
            <svg
              aria-hidden="true"
              role="status"
              className="inline mr-3 w-4 h-4 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              ></path>
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              ></path>
            </svg>
          ) : (
            "Update"
          )}
        </button>
      </div>
    </form>
  );
};

const CryptoForm = ({ id, setCryptoReFetch, allCryptoDenomination }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, setValue, reset } = useForm();
  const setCryptoHandler = async (data) => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${BASE_URL}admin/createCryptoAddressAndRate`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const message = res.data.message;
      toast.success(message);
      setCryptoReFetch(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.message || err?.data.message);
    }
  };
  useEffect(() => {
    setValue("cryptoAsset_id", id);
  }, [setValue, id]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(setCryptoHandler)(e);
        reset();
      }}
      className="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96 bg-[#FCFCFC]"
    >
      <div className="w-full p-4 flex items-center gap-x-2">
        <div className="hidden">
          <label htmlFor="SellingRate" className="block text-xs text-gray-500 ">
            id
          </label>

          <input
            type="text"
            required
            {...register("cryptoAsset_id")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>
        <div className="w-full">
          <label htmlFor="SellingRate" className="block text-xs text-gray-500 ">
            Selling Rate
          </label>

          <input
            type="text"
            required
            {...register("selling_rate")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>

        <div className="w-full">
          <label htmlFor="BuyingRate" className="block text-xs text-gray-500 ">
            Buying Rate
          </label>

          <input
            type="text"
            required
            {...register("buying_rate")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>
      </div>

      <div className="w-full">
        <div className="w-full px-4">
          <label
            htmlFor="Network Address"
            className="block text-xs text-gray-500 "
          >
            Network Address
          </label>
        </div>
        <div className="px-4 pb-4 flex gap-x-3 items- justify-between ">
          <input
            type="text"
            required
            {...register("networkAddress")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>
      </div>

      <div className="flex px-4 gap-x-4 mb-3">
        <div className="w-full">
          <label htmlFor="SellingRate" className="block text-xs text-gray-500 ">
            Network Name
          </label>
          <input
            type="text"
            required
            {...register("networkName")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="Denomination"
            className="block text-xs text-gray-500 "
          >
            Denomination
          </label>
          <div className="w-full ">
            <div className="relative">
              <select
                required
                {...register("denomination_id")}
                className="py-3 my-1 block appearance-none border text-[#0C0C0C] bg-[#FFF] rounded-lg w-full focus:outline-none px-5 text-sm"
              >
                <option>Select Denomination</option>
                {allCryptoDenomination.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.denomination}
                  </option>
                ))}
              </select>

              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex px-4 gap-x-4 mb-3">
        <button
          type="submit"
          className="font-semibold w-[12rem]  mb-4 transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#284C84]  px-3 py-2.5 rounded-lg "
        >
          {loading ? <Spinner /> : "Set"}
        </button>
      </div>
    </form>
  );
};
const WalletForm = ({
  id,
  setCryptoReFetch,
  details,
  allCryptoDenomination,
}) => {
  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset, setValue } = useForm();

  const setCryptoHandler = async (data) => {
    try {
      setLoading(true);
      const res = await axios.put(
        `${BASE_URL}admin/upDateCryptoAddressAndRate/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const message = res.data.message;
      setCryptoReFetch(true);
      toast.success(message);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.message || err?.data.message);
    }
  };
  useEffect(() => {
    setValue("selling_rate", details.selling_rate);
    setValue("buying_rate", details.buying_rate);
    setValue("networkAddress", details.networkAddress);
    setValue("denomination_id", details.denomination);
  }, [
    setValue,
    details.selling_rate,
    details.buying_rate,
    details.networkAddress,
    details.denomination_id,
  ]);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(setCryptoHandler)(e);
        reset();
      }}
      className="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96 bg-[#FCFCFC]"
    >
      <div className="w-full p-4 flex items-center gap-x-2">
        <div className="w-full">
          <label htmlFor="SellingRate" className="block text-xs text-gray-500 ">
            Selling Rate
          </label>

          <input
            type="text"
            required
            {...register("selling_rate")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>

        <div className="w-full">
          <label htmlFor="BuyingRate" className="block text-xs text-gray-500 ">
            Buying Rate
          </label>

          <input
            type="text"
            required
            {...register("buying_rate")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>
      </div>

      <div className="flex px-4 gap-x-4 mb-3">
        <div className="w-full">
          <label htmlFor="SellingRate" className="block text-xs text-gray-500 ">
            Network Address
          </label>
          <input
            type="text"
            required
            {...register("networkAddress")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="Denomination"
            className="block text-xs text-gray-500 "
          >
            Denomination
          </label>
          <div className="w-full ">
            <div className="relative">
              <select
                required
                {...register("denomination_id")}
                className="py-3 my-1 block appearance-none border text-[#0C0C0C] bg-[#FFF] rounded-lg w-full focus:outline-none px-5 text-sm"
              >
                <option>Select Denomination</option>
                {allCryptoDenomination.map((item, i) => (
                  <option key={i} value={item.denomination}>
                    {item.denomination}
                  </option>
                ))}
              </select>

              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 pb-4 flex gap-x-3 items- justify-between ">
        <button
          type="submit"
          className="font-semibold w-36 transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#284C84]  px-3 py-2.5 rounded-lg "
        >
          {loading ? <Spinner /> : "Update"}
        </button>
      </div>
    </form>
  );
};
const DeclineGiftForm = ({ id, setDeclineGiftCardModal }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;
  const [isLoading, setLoading] = useState(false);

  const { register, handleSubmit, setValue, reset } = useForm();
  const [loadingUpload, setLoadingUpload] = useState(false);
  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "errorimage"); // Replace with your Cloudinary upload preset
    setLoadingUpload(true);
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dlwff8kwh/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      setValue("image", data.secure_url);
      setLoadingUpload(false);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const declineGiftCardHandler = async (data) => {
    setLoading(true);

    try {
      const res = await axios.post(
        `${BASE_URL}admin/declinedGiftCardStatus/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      setLoading(false);
      const response = res.data.message;
      toast.success(response);
      setDeclineGiftCardModal(false);
      window.location.reload(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.message || err?.data.message);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(declineGiftCardHandler)(e);
        reset();
      }}
      className="overflow-hidden transition-all duration-500 peer-checked:max-h-96 bg-[#FCFCFC] text-left"
    >
      <div className="px-4 pb-4  gap-x-2">
        <div className="w-full">
          <label htmlFor="SellingRate" className="block text-xs text-gray-500 ">
            Enter Decline Message
          </label>

          <input
            type="text"
            required
            {...register("message")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>

        <div className="w-full mt-5">
          <label htmlFor="SellingRate" className="block text-xs text-gray-500 ">
            Add Image
          </label>
          <input type="text" {...register("image")} className="hidden " />
          <div className="flex">
            <label
              class="cursor-pointer inline-flex items-center  -mr-24 px-3 py-2 bg-[#E4E4E4] text-gray-500 rounded-l-2xl font-semibold text-xs  hover:bg-gray-300 active:bg-[#E4E4E4] focus:outline-none disabled:opacity-25 transition ease-in-out duration-150 relative  w-[6rem] z-10"
              for="thumbnail"
            >
              Choose File
            </label>
            <input
              id="thumbnail"
              type="file"
              onChange={handleUpload}
              class="py-3 px-8 border-[0.5px] bg-[#F6F8F8]/50 border-[#757E7E]/20 rounded-2xl w-full focus:outline-none text-xs"
            />
          </div>
        </div>

        <button
          type="submit"
          disabled={loadingUpload}
          className="font-semibold w-[15rem] h-[2.5rem] mt-5 transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#284C84]  px-3 py-2.5 rounded-lg "
        >
          {isLoading ? <Spinner /> : " Decline Gift Card"}
        </button>
      </div>
    </form>
  );
};

const ApproveGiftForm = ({ id, setApproveGiftCardModal, refetch }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;
  const [isLoading, setLoading] = useState(false);

  const { register, handleSubmit, setValue, reset } = useForm();
  const approveGiftCardHandler = async (data) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${BASE_URL}admin/updateGiftcardStatus/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      setLoading(false);
      const message = res.data.message;
      toast.success(message);
      setApproveGiftCardModal(false);
      refetch();
    } catch (err) {
      setLoading(false);
      toast.error(err?.message || err?.data.message);
    }
  };

  // Approve GiftCard
  useEffect(() => {
    setValue("status", "approved"); // Set default value for the 'age' field
  }, [setValue, id]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(approveGiftCardHandler)(e);
        reset();
      }}
      className="overflow-hidden transition-all duration-500 peer-checked:max-h-96 bg-[#FCFCFC]"
    >
      <div className="px-4 pb-4 flex gap-x-2">
        <div className="w-full hidden">
          <label htmlFor="Status" className="block text-xs text-gray-500 ">
            Status
          </label>

          <input
            type="text"
            required
            {...register("status")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>
        <div className="w-full">
          <label htmlFor="Status" className="block text-xs text-gray-500 ">
            Enter Approve Message
          </label>

          <input
            type="text"
            required
            {...register("message")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>

        <button
          type="submit"
          className="font-semibold w-[5rem] h-[2.5rem] mt-5 transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#284C84]  px-3 py-2.5 rounded-lg "
        >
          {isLoading ? <Spinner /> : " Submit"}
        </button>
      </div>
    </form>
  );
};

const DeclineCryptoForm = ({ id, setDeclineCryptoModal }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit, setValue, reset } = useForm();
  const [loadingUpload, setLoadingUpload] = useState(false);
  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "errorimage"); // Replace with your Cloudinary upload preset
    setLoadingUpload(true);
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dlwff8kwh/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      setValue("image", data.secure_url);
      setLoadingUpload(false);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const declineCryptoHandler = async (data) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${BASE_URL}admin/declinedCryptoStatus/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      setLoading(false);
      const message = res.data.message;
      toast.success(message);
      setDeclineCryptoModal(false);
      window.location.reload(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.message || err?.data.message);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(declineCryptoHandler)(e);
        reset();
      }}
      className="overflow-hidden transition-all duration-500 peer-checked:max-h-96 bg-[#FCFCFC] text-left"
    >
      <div className="px-4 py-4 gap-x-2">
        <div className="w-full">
          <label htmlFor="Message" className="block text-xs text-gray-500 ">
            Enter Decline Message
          </label>

          <input
            type="text"
            required
            {...register("message")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>

        <div className="w-full mt-5">
          <label
            htmlFor="AddImage"
            className="block pb-2 text-xs text-gray-500 "
          >
            Add Image
          </label>
          <input type="text" {...register("image")} className="hidden " />
          <div className="flex">
            <label
              class="cursor-pointer inline-flex items-center  -mr-24 px-3 py-2 bg-[#E4E4E4] text-gray-500 rounded-l-2xl font-semibold text-xs  hover:bg-gray-300 active:bg-[#E4E4E4] focus:outline-none disabled:opacity-25 transition ease-in-out duration-150 relative  w-[6rem] z-10"
              for="thumbnail"
            >
              Choose File
            </label>
            <input
              id="thumbnail"
              type="file"
              onChange={handleUpload}
              class="py-3 px-8 border-[0.5px] bg-[#F6F8F8]/50 border-[#757E7E]/20 rounded-2xl w-full focus:outline-none text-xs"
            />
          </div>
        </div>

        <button
          type="submit"
          disabled={loadingUpload}
          className="font-semibold w-[15rem] h-[2.5rem] mt-5 transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#284C84]  px-3 py-2.5 rounded-lg "
        >
          {isLoading ? <Spinner /> : "Decline"}
        </button>
      </div>
    </form>
  );
};

function GiftCards() {
  const [openTab, setOpenTab] = useState(1);
  const [crypto, setCrypto] = useState([]);
  const [singleCrypto, setSingleCrypto] = useState([]);
  const [singleGiftCard, setSingleGiftCard] = useState([]);
  const [giftCard, setGiftCard] = useState([]);
  const [cryptoModal, setCryptoModal] = useState(false);
  const [walletAddressModal, setWalletAddressModal] = useState(false);
  const [giftCardModal, setGiftCardModal] = useState(false);
  const [declineCryptoModal, setDeclineCryptoModal] = useState(false);
  const [declineGiftCardModal, setDeclineGiftCardModal] = useState(false);
  const [cryptoRateModal, setCryptoRateModal] = useState(false);
  const [giftCardRateModal, setGiftCardRateModal] = useState(false);
  const [cryptoDenominationModal, setCryptoDenominationModal] = useState(false);
  const [giftCardDenominationModal, setGiftCardDenominationModal] =
    useState(false);
  const [editGiftCardRateModal, setEditGiftCardRateModal] = useState(false);
  const [approveGiftCardModal, setApproveGiftCardModal] = useState(false);
  const [allGiftCard, setAllGiftCard] = useState([]);
  const [allGiftCardDenomination, setAllGiftCardDenomination] = useState([]);
  const [allCryptoAsset, setAllCryptoAsset] = useState([]);
  const [allCryptoDenomination, setAllCryptoDenomination] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);

  const [cryptoReFetch, setCryptoReFetch] = useState(false);
  const [declineId, setDeclineId] = useState("");
  const [approveId, setApproveId] = useState("");
  const [countries, setCountries] = useState([]);
  const { data, isLoading, refetch, error } =
    useGetUserCryptoSellTransactionQuery();
  const { data: response, refetch: refresh } =
    useGetUserSellGiftcardTransactionQuery();

  useEffect(() => {
    if (data) {
      setCrypto(data.cryptoTransaction);
    }
    if (response) {
      setGiftCard(response.giftcardTransaction);
    }
  }, [data, response]);

  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;

  const fetchCountries = async () => {
    try {
      const response = await fetch(`${BASE_URL}admin/getCountry`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch countries");
      }
      const res = await response.json();
      setCountries(res);
    } catch (error) {
      console.error("Error fetching countries:", error.message);
    }
  };
  // Fetch Gift Card Denomination
  const fetchGiftCardDenomination = async () => {
    try {
      const res = await axios.get(`${BASE_URL}admin/fetchDenomination`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      });
      const resp = await res.data.data;

      setAllGiftCardDenomination(resp);
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };

  // Fetch Crypto Denomination
  const fetchCryptoDenomination = async () => {
    try {
      const res = await axios.get(`${BASE_URL}admin/fetchCryptoDenomination`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      });
      const resp = await res.data.data;
      setAllCryptoDenomination(resp);
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };
  // Get Single Crypto Transaction
  const getCryptoHandler = async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}admin/getSingleCryptoTransaction/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      );
      const resp = await res.data.cryptoTransaction;
      setSingleCrypto(resp);
      setCryptoModal(true);
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };

  // Get Single Gift Card Transaction
  const getGiftCardHandler = async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}admin/getSingleGiftcardTransaction/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      );
      const respi = await res.data.giftcardTransaction;
      setSingleGiftCard(respi);
      setGiftCardModal(true);
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };

  // Fetch Gift Card Denomination
  const fetchDenominationHandler = async () => {
    try {
      const res = await axios.get(`${BASE_URL}admin/fetchDenomination`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      });
      const resp = await res.data.data;
      setAllGiftCardDenomination(resp);
      setGiftCardDenominationModal(true);
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };

  // Fetch Crypto Denomination
  const fetchCryptoDenominationHandler = async () => {
    try {
      const res = await axios.get(`${BASE_URL}admin/fetchCryptoDenomination`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      });
      const resp = await res.data.data;
      setAllCryptoDenomination(resp);
      setCryptoDenominationModal(true);
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };

  // Fetch Gift Card
  const fetchGiftCardHandler = async () => {
    try {
      const res = await axios.get(`${BASE_URL}admin/fetchGiftCard`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      });
      const resp = await res.data.giftCards;
      setAllGiftCard(resp);
      setGiftCardRateModal(true);
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };

  // Fetch Gift Card
  const editGiftCardHandler = async () => {
    try {
      const res = await axios.get(`${BASE_URL}admin/fetchGiftCard`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      });
      const resp = await res.data.giftCards;
      setAllGiftCard(resp);
      setEditGiftCardRateModal(true);
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };

  // refetchGiftCardHandler
  if (reFetch) {
    (async () => {
      try {
        const res = await axios.get(`${BASE_URL}admin/fetchGiftCard`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        });
        const resp = await res.data.giftCards;
        setAllGiftCard(resp);
        setGiftCardRateModal(true);
        setReFetch(false);
      } catch (err) {
        toast.error(err?.data?.message);
      }
    })();
  }

  const [searchQuery, setSearchQuery] = useState("");

  // Filter the data based on the search query
  const filteredCards = allGiftCard.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Fetch Crypto
  const fetchCryptoAssetHandler = async () => {
    try {
      const res = await axios.get(`${BASE_URL}admin/fetchCryptoAsset`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      });
      const resp = await res.data;
      setAllCryptoAsset(resp);
      setCryptoRateModal(true);
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };
  // Filter the data based on the search query
  const filteredCrypto = allCryptoAsset.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // Fetch Crypto
  const fetchCryptoWalletHandler = async () => {
    try {
      const res = await axios.get(`${BASE_URL}admin/fetchCryptoAsset`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      });
      const resp = await res.data;
      setAllCryptoAsset(resp);
      setWalletAddressModal(true);
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };

  if (cryptoReFetch) {
    (async () => {
      try {
        const res = await axios.get(`${BASE_URL}admin/fetchCryptoAsset`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        });
        const resp = await res.data;
        setAllCryptoAsset(resp);
        setCryptoRateModal(true);
        setCryptoReFetch(false);
      } catch (err) {
        toast.error(err?.data?.message);
      }
    })();
  }

  // Approve Crypto
  const approveHandler = async (id) => {
    try {
      setLoading(true);
      const res = await axios.put(
        `${BASE_URL}admin/updateCryptoStatus/${id}`,
        { status: "approve" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      );
      setLoading(false);
      toast.success(res.message);
      refetch();
      setCryptoModal(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.message || err?.data.message);
    }
  };

  return (
    <div className="px-10">
      <div className="flex justify-between items-center  gap-x-2  my-10 ">
        <div className="">
          <h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
            <Link to="/dashboard/index">Home</Link>
            <span className=" text-gray-500  rtl:-scale-x-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>{" "}
            <Link to="/dashboard/merchant">Merchant Financial Base</Link>
            <span className=" text-gray-500  rtl:-scale-x-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>{" "}
            Gift Card & Crypto transaction
          </h2>
          <Link
            to="/dashboard/merchant"
            className="text-[#4E4E4E] text-sm font-light text-left flex items-center gap-x-2 mt-3"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                stroke="#0C0C0C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                stroke="#0C0C0C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Back
          </Link>
        </div>

        <Search />
      </div>

      <div className="flex justify-between items-center  gap-x-2 my-5 ">
        <div>
          <h2 className="text-[#0C0C0C]   text-lg font-extrabold ">
            Successful Gift Card & Crypto transaction
          </h2>
        </div>

        <div className="w-[10rem] ">
          <div className="relative">
            <select className="py-2 my-1 block appearance-none border text-[#0C0C0C] bg-[#FFF] rounded-sm w-full focus:outline-none px-5 text-sm">
              <option selected>Select</option>
              <option value="month">Monthly</option>
              <option value="year">Yearly</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center  gap-x-2  ">
        <div className="flex   rtl:flex-row-reverse   w-screen sm:w-min overflow-x-auto scrollbar-hide inset-x-0 mt-2">
          <button
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(1);
            }}
            className={` px-2 text-xs ${
              openTab === 1
                ? "border-b-4 border-[#264A83] font-extrabold text-gray-900 "
                : "text-[#264A83]/60"
            } transition-colors duration-200    hover:bg-gray-100`}
          >
            <span className="w-[4.5rem] py-1  flex justify-center text-center">
              Gift Card
            </span>
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(2);
            }}
            className={` px-2 text-xs ${
              openTab === 2
                ? "border-b-4 border-[#264A83] font-extrabold text-gray-900 "
                : "text-[#264A83]/60"
            } transition-colors duration-200    hover:bg-gray-100`}
          >
            <span className="w-[8.5rem] py-1 flex justify-center text-center">
              Crypto transaction
            </span>
          </button>
        </div>
        <div className={openTab === 1 ? "flex gap-x-2" : "hidden"}>
          <button
            onClick={(e) => {
              e.preventDefault();
              fetchDenominationHandler();
            }}
            className="flex items-center gap-x-2 transition-colors duration-200 text-xs hover:text-gray-300 focus:outline-none text-[#fff] bg-[#264A83]/50  px-5 py-2 rounded-lg "
          >
            <svg
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.2349 11.2346H0.765625M11.0002 1V21.4692M12.0237 11.2346C12.0237 12.3204 12.455 13.3617 13.2228 14.1294C13.9905 14.8972 15.0318 15.3285 16.1176 15.3285M9.97678 11.2346C9.97678 12.3204 9.54547 13.3617 8.77772 14.1294C8.00998 14.8972 6.96869 15.3285 5.88293 15.3285"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M11.0003 9.2235C11.0003 8.48232 11.2481 7.76243 11.7043 7.17827C12.1605 6.59412 12.7989 6.17924 13.518 5.9996C15.1586 5.59021 16.6457 7.07628 16.2353 8.71791C16.0556 9.43699 15.6407 10.0754 15.0566 10.5316C14.4724 10.9878 13.7525 11.2356 13.0114 11.2356H11.0003M11.0003 9.2235V11.2356M11.0003 9.2235C11.0002 8.48232 10.7524 7.76243 10.2962 7.17827C9.84001 6.59412 9.20162 6.17924 8.48254 5.9996C6.84193 5.59021 5.35484 7.07628 5.76525 8.71791C5.9449 9.43699 6.35978 10.0754 6.94393 10.5316C7.52808 10.9878 8.24798 11.2356 8.98916 11.2356H11.0003"
                stroke="white"
                stroke-width="1.5"
              />
              <path
                d="M0.765625 11.2346C0.765625 6.41002 0.765625 3.99772 2.26397 2.49835C3.76437 1 6.17564 1 11.0002 1C15.8248 1 18.2371 1 19.7355 2.49835C21.2349 3.99874 21.2349 6.41002 21.2349 11.2346C21.2349 16.0592 21.2349 18.4715 19.7355 19.9699C18.2382 21.4692 15.8248 21.4692 11.0002 21.4692C6.17564 21.4692 3.76335 21.4692 2.26397 19.9699C0.765625 18.4725 0.765625 16.0592 0.765625 11.2346Z"
                stroke="white"
                stroke-width="1.5"
              />
            </svg>
            Denominations
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              fetchGiftCardHandler();
              fetchCountries();
              fetchGiftCardDenomination();
            }}
            className="flex items-center gap-x-2 transition-colors duration-200 text-xs hover:text-gray-300 focus:outline-none text-[#fff] bg-[#264A83]  px-5 py-2 rounded-lg "
          >
            <svg
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.2349 11.2346H0.765625M11.0002 1V21.4692M12.0237 11.2346C12.0237 12.3204 12.455 13.3617 13.2228 14.1294C13.9905 14.8972 15.0318 15.3285 16.1176 15.3285M9.97678 11.2346C9.97678 12.3204 9.54547 13.3617 8.77772 14.1294C8.00998 14.8972 6.96869 15.3285 5.88293 15.3285"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M11.0003 9.2235C11.0003 8.48232 11.2481 7.76243 11.7043 7.17827C12.1605 6.59412 12.7989 6.17924 13.518 5.9996C15.1586 5.59021 16.6457 7.07628 16.2353 8.71791C16.0556 9.43699 15.6407 10.0754 15.0566 10.5316C14.4724 10.9878 13.7525 11.2356 13.0114 11.2356H11.0003M11.0003 9.2235V11.2356M11.0003 9.2235C11.0002 8.48232 10.7524 7.76243 10.2962 7.17827C9.84001 6.59412 9.20162 6.17924 8.48254 5.9996C6.84193 5.59021 5.35484 7.07628 5.76525 8.71791C5.9449 9.43699 6.35978 10.0754 6.94393 10.5316C7.52808 10.9878 8.24798 11.2356 8.98916 11.2356H11.0003"
                stroke="white"
                stroke-width="1.5"
              />
              <path
                d="M0.765625 11.2346C0.765625 6.41002 0.765625 3.99772 2.26397 2.49835C3.76437 1 6.17564 1 11.0002 1C15.8248 1 18.2371 1 19.7355 2.49835C21.2349 3.99874 21.2349 6.41002 21.2349 11.2346C21.2349 16.0592 21.2349 18.4715 19.7355 19.9699C18.2382 21.4692 15.8248 21.4692 11.0002 21.4692C6.17564 21.4692 3.76335 21.4692 2.26397 19.9699C0.765625 18.4725 0.765625 16.0592 0.765625 11.2346Z"
                stroke="white"
                stroke-width="1.5"
              />
            </svg>
            Set Giftcard Rate
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              editGiftCardHandler();
              fetchCountries();
              fetchGiftCardDenomination();
            }}
            className="flex items-center gap-x-2 transition-colors duration-200 text-xs hover:text-gray-300 focus:outline-none text-[#fff] bg-[#3c495e]  px-5 py-2 rounded-lg "
          >
            <svg
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.2349 11.2346H0.765625M11.0002 1V21.4692M12.0237 11.2346C12.0237 12.3204 12.455 13.3617 13.2228 14.1294C13.9905 14.8972 15.0318 15.3285 16.1176 15.3285M9.97678 11.2346C9.97678 12.3204 9.54547 13.3617 8.77772 14.1294C8.00998 14.8972 6.96869 15.3285 5.88293 15.3285"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M11.0003 9.2235C11.0003 8.48232 11.2481 7.76243 11.7043 7.17827C12.1605 6.59412 12.7989 6.17924 13.518 5.9996C15.1586 5.59021 16.6457 7.07628 16.2353 8.71791C16.0556 9.43699 15.6407 10.0754 15.0566 10.5316C14.4724 10.9878 13.7525 11.2356 13.0114 11.2356H11.0003M11.0003 9.2235V11.2356M11.0003 9.2235C11.0002 8.48232 10.7524 7.76243 10.2962 7.17827C9.84001 6.59412 9.20162 6.17924 8.48254 5.9996C6.84193 5.59021 5.35484 7.07628 5.76525 8.71791C5.9449 9.43699 6.35978 10.0754 6.94393 10.5316C7.52808 10.9878 8.24798 11.2356 8.98916 11.2356H11.0003"
                stroke="white"
                stroke-width="1.5"
              />
              <path
                d="M0.765625 11.2346C0.765625 6.41002 0.765625 3.99772 2.26397 2.49835C3.76437 1 6.17564 1 11.0002 1C15.8248 1 18.2371 1 19.7355 2.49835C21.2349 3.99874 21.2349 6.41002 21.2349 11.2346C21.2349 16.0592 21.2349 18.4715 19.7355 19.9699C18.2382 21.4692 15.8248 21.4692 11.0002 21.4692C6.17564 21.4692 3.76335 21.4692 2.26397 19.9699C0.765625 18.4725 0.765625 16.0592 0.765625 11.2346Z"
                stroke="white"
                stroke-width="1.5"
              />
            </svg>
            Edit Giftcard Rate
          </button>
        </div>
        <div className={openTab === 2 ? "flex gap-x-2" : "hidden"}>
          <button
            onClick={(e) => {
              e.preventDefault();
              fetchCryptoDenominationHandler();
            }}
            className="flex items-center gap-x-2 transition-colors duration-200 text-xs hover:text-gray-300 focus:outline-none text-[#fff] bg-[#264A83]/50  px-5 py-2 rounded-lg "
          >
            <svg
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.2349 11.2346H0.765625M11.0002 1V21.4692M12.0237 11.2346C12.0237 12.3204 12.455 13.3617 13.2228 14.1294C13.9905 14.8972 15.0318 15.3285 16.1176 15.3285M9.97678 11.2346C9.97678 12.3204 9.54547 13.3617 8.77772 14.1294C8.00998 14.8972 6.96869 15.3285 5.88293 15.3285"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M11.0003 9.2235C11.0003 8.48232 11.2481 7.76243 11.7043 7.17827C12.1605 6.59412 12.7989 6.17924 13.518 5.9996C15.1586 5.59021 16.6457 7.07628 16.2353 8.71791C16.0556 9.43699 15.6407 10.0754 15.0566 10.5316C14.4724 10.9878 13.7525 11.2356 13.0114 11.2356H11.0003M11.0003 9.2235V11.2356M11.0003 9.2235C11.0002 8.48232 10.7524 7.76243 10.2962 7.17827C9.84001 6.59412 9.20162 6.17924 8.48254 5.9996C6.84193 5.59021 5.35484 7.07628 5.76525 8.71791C5.9449 9.43699 6.35978 10.0754 6.94393 10.5316C7.52808 10.9878 8.24798 11.2356 8.98916 11.2356H11.0003"
                stroke="white"
                stroke-width="1.5"
              />
              <path
                d="M0.765625 11.2346C0.765625 6.41002 0.765625 3.99772 2.26397 2.49835C3.76437 1 6.17564 1 11.0002 1C15.8248 1 18.2371 1 19.7355 2.49835C21.2349 3.99874 21.2349 6.41002 21.2349 11.2346C21.2349 16.0592 21.2349 18.4715 19.7355 19.9699C18.2382 21.4692 15.8248 21.4692 11.0002 21.4692C6.17564 21.4692 3.76335 21.4692 2.26397 19.9699C0.765625 18.4725 0.765625 16.0592 0.765625 11.2346Z"
                stroke="white"
                stroke-width="1.5"
              />
            </svg>
            Denominations
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              fetchCryptoAssetHandler();
              fetchCryptoDenomination();
            }}
            className="flex items-center gap-x-2 transition-colors duration-200 text-xs hover:text-gray-300 focus:outline-none text-[#fff] bg-[#264A83]  px-5 py-2 rounded-lg "
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.5 12.2346C22.5 17.7574 18.0228 22.2346 12.5 22.2346C6.97715 22.2346 2.5 17.7574 2.5 12.2346C2.5 6.71177 6.97715 2.23462 12.5 2.23462C18.0228 2.23462 22.5 6.71177 22.5 12.2346Z"
                stroke="white"
                stroke-width="1.5"
              />
              <path
                d="M9.5 12.2346H13.7M9.5 12.2346V9.53092C9.5 9.05951 9.5 8.82381 9.64645 8.67736C9.79289 8.53092 10.0286 8.53092 10.5 8.53092H13.7C14.6941 8.53092 15.5 9.36002 15.5 10.3828C15.5 11.4055 14.6941 12.2346 13.7 12.2346M9.5 12.2346V14.9383C9.5 15.4097 9.5 15.6454 9.64645 15.7919C9.79289 15.9383 10.0286 15.9383 10.5 15.9383H13.7C14.6941 15.9383 15.5 15.1092 15.5 14.0865C15.5 13.0637 14.6941 12.2346 13.7 12.2346M10.9938 8.53092V7.23462M10.9938 17.2346V15.9383M13.3982 8.53092V7.23462M13.3982 17.2346V15.9383"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
            Set Crypto Rate
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              fetchCryptoWalletHandler();
              fetchCryptoDenomination();
            }}
            className="flex items-center gap-x-2 transition-colors duration-200 text-xs hover:text-gray-300 focus:outline-none text-[#fff] bg-[#3c495e] px-5 py-2 rounded-lg "
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.5 12.2346C22.5 17.7574 18.0228 22.2346 12.5 22.2346C6.97715 22.2346 2.5 17.7574 2.5 12.2346C2.5 6.71177 6.97715 2.23462 12.5 2.23462C18.0228 2.23462 22.5 6.71177 22.5 12.2346Z"
                stroke="white"
                stroke-width="1.5"
              />
              <path
                d="M9.5 12.2346H13.7M9.5 12.2346V9.53092C9.5 9.05951 9.5 8.82381 9.64645 8.67736C9.79289 8.53092 10.0286 8.53092 10.5 8.53092H13.7C14.6941 8.53092 15.5 9.36002 15.5 10.3828C15.5 11.4055 14.6941 12.2346 13.7 12.2346M9.5 12.2346V14.9383C9.5 15.4097 9.5 15.6454 9.64645 15.7919C9.79289 15.9383 10.0286 15.9383 10.5 15.9383H13.7C14.6941 15.9383 15.5 15.1092 15.5 14.0865C15.5 13.0637 14.6941 12.2346 13.7 12.2346M10.9938 8.53092V7.23462M10.9938 17.2346V15.9383M13.3982 8.53092V7.23462M13.3982 17.2346V15.9383"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
            Update Crypto Rate
          </button>
        </div>
      </div>
      {/* Table+++++++++++++++++++++++++++++++ */}
      <div className={openTab === 1 ? "block" : "hidden"}>
        <div className="flex flex-col my-8">
          <div className="overflow-x-auto rounded-lg h-[30rem] scrollbar-hide">
            <div className="align-middle inline-block min-w-full">
              <div className="shadow overflow-hidden sm:rounded-lg">
                {isLoading ? (
                  <div className="pb-5">
                    <PageLoading />
                  </div>
                ) : error ? (
                  <div className="pt-5">
                    <Loading />
                  </div>
                ) : (
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          TRANSACTION
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          DATE & TIME
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          AMOUNT
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          STATUS
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          ADMIN
                        </th>
                      </tr>
                    </thead>

                    <tbody className="bg-white">
                      {giftCard.map((item, i) => (
                        <tr
                          key={item.id}
                          className={i % 2 === 0 ? "" : "bg-gray-50"}
                        >
                          <td
                            onClick={() => getGiftCardHandler(item.id)}
                            className="cursor-pointer p-4 whitespace-nowrap text-sm font-normal text-gray-900 flex gap-x-2"
                          >
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.20837 1.83333V1.14583C2.82868 1.14583 2.52087 1.45364 2.52087 1.83333H3.20837ZM18.7917 1.83333H19.4792C19.4792 1.45364 19.1714 1.14583 18.7917 1.14583V1.83333ZM3.20837 20.1667H2.52087C2.52087 20.349 2.59331 20.5239 2.72224 20.6528C2.85117 20.7817 3.02604 20.8542 3.20837 20.8542V20.1667ZM12.375 20.1667V20.8542H12.6598L12.8611 20.6528L12.375 20.1667ZM18.7917 13.75L19.2778 14.2361L19.4792 14.0348V13.75H18.7917ZM12.375 13.75V13.0625H11.6875V13.75H12.375ZM3.20837 2.52083H18.7917V1.14583H3.20837V2.52083ZM3.89587 20.1667V1.83333H2.52087V20.1667H3.89587ZM3.20837 20.8542H12.375V19.4792H3.20837V20.8542ZM19.4792 13.75V1.83333H18.1042V13.75H19.4792ZM12.8611 20.6528L19.2778 14.2361L18.3056 13.2639L11.8889 19.6806L12.8611 20.6528ZM13.0625 20.1667V13.75H11.6875V20.1667H13.0625ZM12.375 14.4375H18.7917V13.0625H12.375V14.4375Z"
                                fill="#141B34"
                              />
                              <path
                                d="M7.33337 6.41667H14.6667"
                                stroke="#141B34"
                                stroke-width="1.5"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.33337 10.0833H11"
                                stroke="#141B34"
                                stroke-width="1.5"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Payment from{" "}
                            <span className="font-semibold">
                              {item.user === null ? "" : item.user.full_name}
                            </span>
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                            <FormattedDate date={item?.created_at} />
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            {item.card_range}{" "}
                            {item.amount_of_the_card.toLocaleString()}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            {item.status === "approve" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  Approved
                                </span>
                              </span>
                            )}
                            {item.status === "approved" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  Approved
                                </span>
                              </span>
                            )}
                            {item.status === "declined" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  Declined
                                </span>
                              </span>
                            )}
                            {item.status === "pending" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  In progress
                                </span>
                              </span>
                            )}{" "}
                            {item.status === "completed" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  Completed
                                </span>
                              </span>
                            )}
                          </td>{" "}
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            {item.admin_updatedBy}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* +++++++++++++++++++++++Crypto Transactions +++++++++++++++++++++++++++++ */}
      <div className={openTab === 2 ? "block" : "hidden"}>
        <div className="flex flex-col my-8">
          <div className="overflow-x-auto rounded-lg h-[30rem] scrollbar-hide">
            <div className="align-middle inline-block min-w-full">
              <div className="shadow overflow-hidden sm:rounded-lg">
                {isLoading ? (
                  <div className="pb-5">
                    <PageLoading />
                  </div>
                ) : error ? (
                  <div className="pt-5">
                    <Loading />
                  </div>
                ) : (
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          TRANSACTION
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          DATE & TIME
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          AMOUNT
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          STATUS
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          ADMIN
                        </th>
                      </tr>
                    </thead>

                    <tbody className="bg-white">
                      {crypto.map((item, i) => (
                        <tr key={i} className={i % 2 === 0 ? "" : "bg-gray-50"}>
                          <td
                            onClick={() => getCryptoHandler(item.id)}
                            className="cursor-pointer p-4 whitespace-nowrap text-sm font-normal text-gray-900 flex gap-x-2"
                          >
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.20837 1.83333V1.14583C2.82868 1.14583 2.52087 1.45364 2.52087 1.83333H3.20837ZM18.7917 1.83333H19.4792C19.4792 1.45364 19.1714 1.14583 18.7917 1.14583V1.83333ZM3.20837 20.1667H2.52087C2.52087 20.349 2.59331 20.5239 2.72224 20.6528C2.85117 20.7817 3.02604 20.8542 3.20837 20.8542V20.1667ZM12.375 20.1667V20.8542H12.6598L12.8611 20.6528L12.375 20.1667ZM18.7917 13.75L19.2778 14.2361L19.4792 14.0348V13.75H18.7917ZM12.375 13.75V13.0625H11.6875V13.75H12.375ZM3.20837 2.52083H18.7917V1.14583H3.20837V2.52083ZM3.89587 20.1667V1.83333H2.52087V20.1667H3.89587ZM3.20837 20.8542H12.375V19.4792H3.20837V20.8542ZM19.4792 13.75V1.83333H18.1042V13.75H19.4792ZM12.8611 20.6528L19.2778 14.2361L18.3056 13.2639L11.8889 19.6806L12.8611 20.6528ZM13.0625 20.1667V13.75H11.6875V20.1667H13.0625ZM12.375 14.4375H18.7917V13.0625H12.375V14.4375Z"
                                fill="#141B34"
                              />
                              <path
                                d="M7.33337 6.41667H14.6667"
                                stroke="#141B34"
                                stroke-width="1.5"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.33337 10.0833H11"
                                stroke="#141B34"
                                stroke-width="1.5"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Payment from{" "}
                            <span className="font-semibold">
                              {item.user === null ? "" : item.user.full_name}
                            </span>
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                            <FormattedDate date={item?.created_at} />
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            USD {item.USD_rate.toLocaleString()}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            {item.status === "approve" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  Approved
                                </span>
                              </span>
                            )}
                            {item.status === "declined" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  Declined
                                </span>
                              </span>
                            )}
                            {item.status === "pending" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  In progress
                                </span>
                              </span>
                            )}
                            {item.status === "completed" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  Completed
                                </span>
                              </span>
                            )}
                          </td>{" "}
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            {item.admin_updatedBy}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* +++++++++++++++++++++++Crypto Modal +++++++++++++++++++++++++++++ */}
      {cryptoModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-lg sm:p-6 md:p-8 sm:align-middle ">
              <div className="py-2">
                {singleCrypto.status === "completed" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Completed
                    </span>
                  </span>
                )}

                {singleCrypto.status === "declined" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Declined
                    </span>
                  </span>
                )}

                {singleCrypto.status === "pending" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      In progress
                    </span>
                  </span>
                )}
              </div>
              <h3 className="text-[#000] text-lg font-semibold py-3">
                Crypto transaction Info
              </h3>

              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C] text-left  text-sm font-semibold ">
                  Transaction Reference
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-semibold  rounded-lg mt-1 text-left">
                  {singleCrypto.reference}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Transaction Date & Time
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-semibold  rounded-lg mt-1 text-left">
                  <FormattedDate date={singleCrypto?.created_at} />
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Asset
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-semibold  rounded-lg mt-1 text-left">
                  {singleCrypto.asset_type}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Amount
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-semibold  rounded-lg mt-1 text-left">
                  USD {singleCrypto.USD_rate}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-center text-sm font-thin ">
                  <svg
                    className="mx-auto my-1"
                    width="20"
                    height="13"
                    viewBox="0 0 20 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 3.13184C4 0.203219 7 1.06033 10 3.20313C13 5.34591 16 6.20304 19 3.27442"
                      stroke="#141B34"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1 10.1631C4 7.23446 7 8.09159 10 10.2344C13 12.3772 16 13.2343 19 10.3057"
                      stroke="#141B34"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-semibold  rounded-lg mt-1 text-left">
                  NGN {singleCrypto.NGN_rate.toLocaleString()}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Sender’s name
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-semibold  rounded-lg mt-1 text-left">
                  {singleCrypto.user.full_name}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Sender’s Phone Number
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-semibold  rounded-lg mt-1 text-left">
                  {singleCrypto.user.phone_number}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Sender’s Account Number
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleCrypto.user.account_number}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Sender’s Bank Name
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleCrypto.user.bank_name}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left text-sm font-thin ">
                  Asset Image
                </h2>

                <img
                  src={singleCrypto.image}
                  alt="Asset"
                  className="h-auto w-full py-1"
                />

                {/* {singleCrypto.image.slice(0, 2).map((item, i) => (
                  <img
                    key={i}
                    src={item.url}
                    alt="card"
                    className="h-auto w-full py-1"
                  />
                ))} */}
              </div>
              {singleCrypto.status === "pending" ? (
                <div className="flex gap-x-3 items-start justify-between mt-2">
                  <button
                    onClick={() => {
                      const id = singleCrypto.id;
                      approveHandler(id);
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0AB02E]  px-3 py-4 rounded-lg "
                  >
                    {loading ? <Spinner /> : "Approve"}
                  </button>
                  <button
                    onClick={() => {
                      setCryptoModal(false);
                      setDeclineCryptoModal(true);
                      setDeclineId(singleCrypto.id);
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
                  >
                    Decline
                  </button>
                  <button
                    onClick={() => {
                      setCryptoModal(false);
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-gray-500  px-3 py-4 rounded-lg "
                  >
                    Close
                  </button>
                </div>
              ) : (
                <div className="flex gap-x-3 items-start justify-between mt-2">
                  <button
                    onClick={() => {
                      setCryptoModal(false);
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-gray-500  px-3 py-4 rounded-lg "
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* +++++++++++++++++++++++Giftcard Modal +++++++++++++++++++++++++++++ */}
      {giftCardModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-lg sm:p-6 md:p-8 sm:align-middle ">
              <div className="py-2">
                {singleGiftCard.status === "completed" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Completed
                    </span>
                  </span>
                )}
                {singleGiftCard.status === "approved" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Approved
                    </span>
                  </span>
                )}

                {singleGiftCard.status === "approve" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Approved
                    </span>
                  </span>
                )}

                {singleGiftCard.status === "cancelled" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Cancelled
                    </span>
                  </span>
                )}
                {singleGiftCard.status === "declined" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Declined
                    </span>
                  </span>
                )}

                {singleGiftCard.status === "pending" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      In progress
                    </span>
                  </span>
                )}
              </div>
              <h3 className="text-[#000] text-lg font-semibold py-3">
                Gift Card transaction Info
              </h3>

              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C] text-left  text-sm font-semibold ">
                  Transaction Reference
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.reference}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Transaction Date & Time
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  <FormattedDate date={singleGiftCard?.created_at} />
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Asset
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.card_company}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Asset Country
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.country}
                </div>
              </div>

              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Asset Image
                </h2>

                {singleGiftCard.image.slice(0, 2).map((item, i) => (
                  <img
                    key={i}
                    src={item.url}
                    alt="card"
                    className="h-auto w-full py-1"
                  />
                ))}
              </div>

              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Card Value
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.card_range}{" "}
                  {singleGiftCard.amount_of_the_card.toLocaleString()}
                </div>
              </div>

              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Transaction Value
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.card_range}{" "}
                  {singleGiftCard.transaction_value.toLocaleString()}
                </div>
              </div>

              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Sender’s name
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.user.full_name}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Sender’s Phone Number
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.user.phone_number}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Sender’s Account Number
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.user.account_number}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Sender’s Bank Name
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.user.bank_name}
                </div>
              </div>

              {singleGiftCard.status === "pending" ? (
                <div className="flex gap-x-3 items-start justify-between mt-2">
                  <button
                    onClick={() => {
                      setGiftCardModal(false);
                      setApproveId(singleGiftCard.id);
                      setApproveGiftCardModal(true);
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0AB02E]  px-3 py-4 rounded-lg "
                  >
                    {loading ? <Spinner /> : "Approve"}
                  </button>
                  <button
                    onClick={() => {
                      setGiftCardModal(false);
                      setDeclineGiftCardModal(true);
                      setDeclineId(singleGiftCard.id);
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
                  >
                    Decline
                  </button>
                  <button
                    onClick={() => {
                      setGiftCardModal(false);
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-gray-500  px-3 py-4 rounded-lg "
                  >
                    Close
                  </button>
                </div>
              ) : (
                <div className="flex gap-x-3 items-start justify-between mt-2">
                  <button
                    onClick={() => {
                      setGiftCardModal(false);
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-gray-500  px-3 py-4 rounded-lg "
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* +++++++++++++++++++++++Giftcard Rates Modal +++++++++++++++++++++++++++++ */}
      {giftCardRateModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-3xl sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setGiftCardRateModal(false);
                  }}
                  className="flex items-center gap-x-1"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel
                </button>
              </div>
              <div className="py-2"></div>

              <h3 className="text-[#000] text-lg font-semibold py-3">
                Set Gift Card Rate
              </h3>

              <div className="relative ">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg
                    className="w-4 h-4 text-gray-400"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </span>

                <input
                  type="text"
                  className="w-full py-2 pl-8 pr-4 text-gray-700 bg-[#F9F9F9] border rounded-md placeholder:text-xs  focus:border-blue-400  focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
                  placeholder="Search items"
                  autoComplete="off"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <ul className="space-y-4 my-3">
                {filteredCards.map((item, i) => (
                  <li key={i} className="text-left">
                    <label
                      for={"accordion-1" + i}
                      className="relative flex flex-col rounded-md border border-gray-100 shadow"
                    >
                      <input
                        className="peer hidden"
                        type="checkbox"
                        id={"accordion-1" + i}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                      <div className="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
                        <div className="flex items-center justify-between">
                          <h3 className="text-xs text-gray-600 lg:text-sm">
                            {item.name}
                          </h3>

                          <img
                            src={item.image}
                            alt="card"
                            className="h-[3rem] w-[4.5rem] py-1"
                          />
                        </div>
                        {item.gift_card_rate.map((items, i) => (
                          <div
                            key={i}
                            className="items-center gap-x-3 justify-between my-1"
                          >
                            <div className="w-auto relative inline-block px-3 py-1 my-2 font-semibold text-blue-900 leading-tight">
                              <span
                                aria-hidden
                                className="absolute inset-0 bg-blue-200 opacity-50 rounded-full"
                              ></span>
                              <span className="relative text-sm font-normal">
                                {items.country}
                              </span>
                            </div>
                            {items.cardTypes.map((items, i) => (
                              <div key={i}>
                                <h3 className="w-full text-xs text-gray-600 lg:text-sm">
                                  {items.cardType}
                                </h3>
                                <div className="grid grid-cols-3 items-center justify-center gap-x-5">
                                  {items.denominations.map((items, i) => (
                                    <div
                                      key={i}
                                      className="flex items-center gap-x-5"
                                    >
                                      {items.rates.map((items, i) => (
                                        <div
                                          key={i}
                                          className="flex items-center gap-x-5"
                                        >
                                          <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                            <span
                                              aria-hidden
                                              className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                            ></span>
                                            <span className="relative text-sm font-normal">
                                              {items.selling_rate}
                                            </span>
                                          </span>
                                          <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                                            <span
                                              aria-hidden
                                              className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                                            ></span>
                                            <span className="relative text-sm font-normal">
                                              {items.buying_rate}
                                            </span>
                                          </span>
                                        </div>
                                      ))}

                                      <div className="w-full relative inline-block px-3 py-1 my-2 font-semibold text-yellow-900 leading-tight">
                                        <span
                                          aria-hidden
                                          className="absolute inset-0 bg-yellow-200 opacity-50 rounded-full"
                                        ></span>
                                        <span className="relative text-sm font-normal">
                                          {items.denomination}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>

                      <GiftForm
                        id={item.id}
                        setReFetch={setReFetch}
                        countries={countries}
                        allGiftCardDenomination={allGiftCardDenomination}
                      />
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}

      {/* +++++++++++++++++++++++Giftcard Deomination Modal +++++++++++++++++++++++++++++ */}
      {giftCardDenominationModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-lg sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setGiftCardDenominationModal(false);
                  }}
                  className="flex items-center gap-x-1"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel
                </button>
              </div>
              <div className="py-2"></div>

              <h3 className="text-[#000] text-lg font-semibold py-3">
                Gift Card Denomination
              </h3>

              <ul className="space-y-3 my-3">
                {allGiftCardDenomination.map((item, i) => (
                  <li key={i} className="text-left">
                    <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight w-full">
                      <span
                        aria-hidden
                        className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                      ></span>
                      <span className="relative text-sm font-normal">
                        {item.denomination}
                      </span>
                    </span>
                  </li>
                ))}
              </ul>

              <GiftCardDenominationForm />
            </div>
          </div>
        </div>
      )}

      {/* +++++++++++++++++++++++Giftcard Edit Rates Modal +++++++++++++++++++++++++++++ */}
      {editGiftCardRateModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-3xl sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setEditGiftCardRateModal(false);
                  }}
                  className="flex items-center gap-x-1"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel
                </button>
              </div>
              <div className="py-2"></div>

              <h3 className="text-[#000] text-lg font-semibold py-3">
                Edit Gift Card Rate
              </h3>

              <div className="relative ">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg
                    className="w-4 h-4 text-gray-400"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </span>

                <input
                  type="text"
                  className="w-full py-2 pl-8 pr-4 text-gray-700 bg-[#F9F9F9] border rounded-md placeholder:text-xs  focus:border-blue-400  focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
                  placeholder="Search items"
                  autoComplete="off"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className="space-y-4 my-3">
                {filteredCards.map((item, ind) => (
                  <div
                    key={ind}
                    className="relative rounded-lg cursor-pointer select-none items-center py-4 px-5 border"
                  >
                    <div className="flex items-center justify-between">
                      <h3 className="text-xs text-gray-600 lg:text-sm">
                        {item.name}
                      </h3>

                      <img
                        src={item.image}
                        alt="card"
                        className="h-[3rem] w-[4.5rem] py-1"
                      />
                    </div>
                    <ul className="space-y-4 my-3">
                      {item.gift_card_rate.map((items, i) => (
                        <li key={i} className="text-left">
                          <label
                            for={`accordion-${ind}` + i}
                            className="relative flex flex-col rounded-md border border-gray-100 shadow"
                          >
                            <input
                              className="peer hidden"
                              type="checkbox"
                              id={`accordion-${ind}` + i}
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M19 9l-7 7-7-7"
                              />
                            </svg>

                            <div className="relative ml-4 cursor-pointer select-none items-center  pr-12">
                              <div className="w-auto relative inline-block px-3 py-1 my-2 font-semibold text-blue-900 leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-blue-200 opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-sm font-normal">
                                  {items.country}
                                </span>
                              </div>
                              {items.cardTypes.map((items, i) => (
                                <div key={i}>
                                  <h3 className="w-full text-xs text-gray-600 lg:text-sm">
                                    {items.cardType}
                                  </h3>
                                  <div className="grid grid-cols-3 items-center justify-center gap-x-5">
                                    {items.denominations.map((items, i) => (
                                      <div
                                        key={i}
                                        className="flex items-center gap-x-5"
                                      >
                                        {items.rates.map((items, i) => (
                                          <div
                                            key={i}
                                            className="flex items-center gap-x-5"
                                          >
                                            <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                              <span
                                                aria-hidden
                                                className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                              ></span>
                                              <span className="relative text-sm font-normal">
                                                {items.selling_rate}
                                              </span>
                                            </span>
                                            <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                                              <span
                                                aria-hidden
                                                className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                                              ></span>
                                              <span className="relative text-sm font-normal">
                                                {items.buying_rate}
                                              </span>
                                            </span>
                                          </div>
                                        ))}

                                        <div className="w-full relative inline-block px-3 py-1 my-2 font-semibold text-yellow-900 leading-tight">
                                          <span
                                            aria-hidden
                                            className="absolute inset-0 bg-yellow-200 opacity-50 rounded-full"
                                          ></span>
                                          <span className="relative text-sm font-normal">
                                            {items.denomination}
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                            <EditGiftForm
                              id={item.id}
                              setReFetch={setReFetch}
                              countries={countries}
                              details={items}
                              allGiftCardDenomination={allGiftCardDenomination}
                            />
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* +++++++++++++++++++++++Giftcard Deomination Modal +++++++++++++++++++++++++++++ */}
      {cryptoDenominationModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-xl sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setCryptoDenominationModal(false);
                  }}
                  className="flex items-center gap-x-1"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel
                </button>
              </div>
              <div className="py-2"></div>

              <h3 className="text-[#000] text-lg font-semibold py-3">
                Crypto Denomination
              </h3>

              <ul className="space-y-3 my-3">
                {allCryptoDenomination.map((item, i) => (
                  <li key={i} className="text-left w-full">
                    <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight w-full">
                      <span
                        aria-hidden
                        className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                      ></span>
                      <span className="relative text-sm font-normal">
                        {item.denomination}
                      </span>
                    </span>
                  </li>
                ))}
              </ul>

              <CryptoDenominationForm />
            </div>
          </div>
        </div>
      )}

      {/* +++++++++++++++++++++++Crypto Rates Modal +++++++++++++++++++++++++++++ */}
      {cryptoRateModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-4xl sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setCryptoRateModal(false);
                  }}
                  className="flex items-center gap-x-1"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel
                </button>
              </div>

              <h3 className="text-[#000] text-lg font-semibold py-3">
                Set Crypto Rate
              </h3>

              <div className="relative ">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg
                    className="w-4 h-4 text-gray-400"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </span>

                <input
                  type="text"
                  className="w-full py-2 pl-8 pr-4 text-gray-700 bg-[#F9F9F9] border rounded-md placeholder:text-xs  focus:border-blue-400  focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
                  placeholder="Search items"
                  autoComplete="off"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>

              <ul className="space-y-4 my-3">
                {filteredCrypto.map((item, i) => (
                  <li key={i} className="text-left">
                    <label
                      for={"accordion-1" + i}
                      className="relative flex flex-col rounded-md border border-gray-100 shadow"
                    >
                      <input
                        className="peer hidden"
                        type="checkbox"
                        id={"accordion-1" + i}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                      <div className="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
                        <div className="flex items-center justify-between">
                          <h3 className="text-xs text-gray-600 lg:text-sm">
                            {item.name}
                          </h3>
                        </div>
                        {item.crypto_networt_type.map((items, i) => (
                          <div
                            key={i}
                            className="flex items-center gap-x-3 justify-between my-1"
                          >
                            <h3 className="text-xs text-gray-600 lg:text-sm">
                              {items.name}
                            </h3>
                            <div className="flex items-center justify-between">
                              <div className="flex items-center gap-x-2">
                                <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                  <span
                                    aria-hidden
                                    className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                  ></span>
                                  <span className="relative text-sm font-normal">
                                    {items.selling_rate}
                                  </span>
                                </span>
                                <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                                  <span
                                    aria-hidden
                                    className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                                  ></span>
                                  <span className="relative text-sm font-normal">
                                    {items.buying_rate}
                                  </span>
                                </span>
                                <div className="w-full relative inline-block px-3 py-1 my-2 font-semibold text-yellow-900 leading-tight">
                                  <span
                                    aria-hidden
                                    className="absolute inset-0 bg-yellow-200 opacity-50 rounded-full"
                                  ></span>
                                  <span className="relative text-sm font-normal">
                                    {items.denomination &&
                                      items.denomination.denomination_id}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="w-full relative inline-block px-3 py-1 my-2 font-semibold text-blue-900 leading-tight">
                              <span
                                aria-hidden
                                className="absolute inset-0 bg-blue-200 opacity-50 rounded-full"
                              ></span>
                              <span className="relative text-sm font-normal">
                                {items.networkAddress}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                      <CryptoForm
                        id={item.id}
                        setCryptoReFetch={setCryptoReFetch}
                        allCryptoDenomination={allCryptoDenomination}
                      />
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
      {/* +++++++++++++++++++++++Crypto Edit Rates Modal +++++++++++++++++++++++++++++ */}
      {walletAddressModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-4xl sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setWalletAddressModal(false);
                  }}
                  className="flex items-center gap-x-1"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel
                </button>
              </div>
              <div className="py-2"></div>

              <h3 className="text-[#000] text-lg font-semibold py-3">
                Update Cypto Rate
              </h3>

              <div className="relative ">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg
                    className="w-4 h-4 text-gray-400"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </span>

                <input
                  type="text"
                  className="w-full py-2 pl-8 pr-4 text-gray-700 bg-[#F9F9F9] border rounded-md placeholder:text-xs  focus:border-blue-400  focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
                  placeholder="Search items"
                  autoComplete="off"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>

              <div className="space-y-4 my-3">
                {allCryptoAsset.map((item, ind) => (
                  <div
                    key={ind}
                    className="relative rounded-lg cursor-pointer select-none items-center py-4 px-5 border"
                  >
                    <div className="flex items-center justify-between">
                      <h3 className="text-xs font-bold text-gray-600 lg:text-sm">
                        {item.name}
                      </h3>
                    </div>
                    <ul className="space-y-4 my-3">
                      {item.crypto_networt_type.map((items, i) => (
                        <li key={i} className="text-left">
                          <label
                            for={`accordion-${ind}` + i}
                            className="relative flex flex-col rounded-md border border-gray-100 shadow"
                          >
                            <input
                              className="peer hidden"
                              type="checkbox"
                              id={`accordion-${ind}` + i}
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="2"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M19 9l-7 7-7-7"
                              />
                            </svg>

                            <div className="relative ml-4 cursor-pointer select-none items-center  pr-12">
                              <div className="flex items-center gap-x-3 justify-between my-1">
                                <h3 className="text-xs text-gray-600 lg:text-sm">
                                  {items.name}
                                </h3>
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center gap-x-2">
                                    <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                      <span
                                        aria-hidden
                                        className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                      ></span>
                                      <span className="relative text-sm font-normal">
                                        {items.selling_rate}
                                      </span>
                                    </span>
                                    <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                                      <span
                                        aria-hidden
                                        className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                                      ></span>
                                      <span className="relative text-sm font-normal">
                                        {items.buying_rate}
                                      </span>
                                    </span>
                                    <div className="w-full relative inline-block px-3 py-1 my-2 font-semibold text-yellow-900 leading-tight">
                                      <span
                                        aria-hidden
                                        className="absolute inset-0 bg-yellow-200 opacity-50 rounded-full"
                                      ></span>
                                      <span className="relative text-sm font-normal">
                                        {items.denomination &&
                                          items.denomination.denomination}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="w-full relative inline-block px-3 py-1 my-2 font-semibold text-blue-900 leading-tight">
                                  <span
                                    aria-hidden
                                    className="absolute inset-0 bg-blue-200 opacity-50 rounded-full"
                                  ></span>
                                  <span className="relative text-sm font-normal">
                                    {items.networkAddress}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <WalletForm
                              id={items.id}
                              setCryptoReFetch={setCryptoReFetch}
                              details={items}
                              allCryptoDenomination={allCryptoDenomination}
                            />
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* +++++++++++++++++++++++Crypto Decline Modal +++++++++++++++++++++++++++++ */}
      {declineCryptoModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-lg sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setDeclineCryptoModal(false);
                  }}
                  className="flex items-center gap-x-1"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel
                </button>
              </div>
              <DeclineCryptoForm
                id={declineId}
                setDeclineCryptoModal={setDeclineCryptoModal}
              />
            </div>
          </div>
        </div>
      )}

      {/* +++++++++++++++++++++++Giftcard Decline Modal +++++++++++++++++++++++++++++ */}
      {declineGiftCardModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-lg sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setDeclineGiftCardModal(false);
                  }}
                  className="flex items-center gap-x-1"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel
                </button>
              </div>
              <DeclineGiftForm
                id={declineId}
                setDeclineGiftCardModal={setDeclineGiftCardModal}
              />
            </div>
          </div>
        </div>
      )}

      {/* +++++++++++++++++++++++Aprove GiftCard Modal +++++++++++++++++++++++++++++ */}
      {approveGiftCardModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-lg sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setApproveGiftCardModal(false);
                  }}
                  className="flex items-center gap-x-1"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel
                </button>
              </div>
              <ApproveGiftForm
                id={approveId}
                setApproveGiftCardModal={setApproveGiftCardModal}
                refetch={refetch}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default GiftCards;
