import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import PageLoading from "../../../components/PageLoading";
import {
  useBanUserMutation,
  useCreateHotelAdminMutation,
  useDeleteUserMutation,
} from "../../../Slices/userApsSlice";
import Spinner from "../../../components/Spinner";
import { useForm, Controller } from "react-hook-form";
import { useGetAllHotelMerchantsQuery } from "../../../Slices/hotelApisSlice";
import axios from "axios";
import { BASE_URL } from "../../../utils";
import { useSelector } from "react-redux";

const MultiSelect = ({ options, control, name }) => (
  <Controller
    control={control}
    name={name}
    defaultValue={[]}
    render={({ field: { onChange, value } }) => {
      // Handler to toggle selected option
      const handleCheckboxChange = (option) => {
        if (value.includes(option)) {
          // Remove the option if it's already selected
          onChange(value.filter((item) => item !== option));
        } else {
          // Add the option if it's not selected
          onChange([...value, option]);
        }
      };

      return (
        <div>
          {/* Display selected facilities as tags/badges */}
          <div className="mt-5">
            <label className="text-left block text-gray-800 font-semibold py-2">
              Facilities:
            </label>
            <div className="flex flex-wrap gap-3">
              {value.map((selectedOption, index) => (
                <div
                  key={index}
                  className="flex items-center px-3 py-1 text-sm rounded-full bg-[#e0e0e0] capitalize"
                >
                  {selectedOption}
                  <button
                    type="button"
                    onClick={() => handleCheckboxChange(selectedOption)}
                    style={{
                      marginLeft: "8px",
                      background: "none",
                      border: "none",
                      color: "red",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    x
                  </button>
                </div>
              ))}
            </div>
          </div>
          {/* Select Facilities  */}
          <label className="text-left block text-gray-800 font-semibold py-2">
            Select Facilities:
          </label>
          <div className="">
            {options.map((option, index) => (
              <label key={index} className="mb-2 flex gap-x-2 capitalize">
                <input
                  type="checkbox"
                  value={option}
                  checked={value.includes(option)}
                  onChange={() => handleCheckboxChange(option)}
                />
                {option}
              </label>
            ))}
          </div>
        </div>
      );
    }}
  />
);

function Hotels() {
  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;
  const [user, setUser] = useState([]);
  const [userId, setUserId] = useState([]);
  const [banModal, setBanModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [unbanModal, setUnbanModal] = useState(false);
  const [deleteSucess, setDeleteSucess] = useState(false);
  const [banSucess, setBanSucess] = useState(false);
  const [unbanSucess, setUnbanSucess] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { data, isLoading, refetch, error } = useGetAllHotelMerchantsQuery();
  const [createModal, setCreateModal] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const { handleSubmit, control, register } = useForm();

  useEffect(() => {
    if (data) {
      setUser(data.data);
    }
  }, [data]);

  const filteredAccounts = user.filter((item) =>
    item.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const [banuser] = useBanUserMutation();
  const banHandler = async (bandata) => {
    try {
      await banuser(bandata);
      setBanModal(false);
      setBanSucess(true);
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const unbanHandler = async (bandata) => {
    try {
      await banuser(bandata);
      setUnbanModal(false);
      setUnbanSucess(true);
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const [deleteuser] = useDeleteUserMutation();
  const deleteHandler = async (deletedata) => {
    try {
      await deleteuser(deletedata);
      setDeleteModal(false);
      setDeleteSucess(true);
      Navigate();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const [loading, setLoading] = useState(false);
  const [isERror, setIsERror] = useState(false);

  const createHandler = async (data) => {
    setLoading(true);
    try {
      const formattedData = {
        ...data,
        facilities: JSON.stringify(data.facilities),
      };
      const res = await axios.post(
        `${BASE_URL}hotel/registerMerchants`,
        formattedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      setLoading(false);
      toast.success(res.data.message);
      refetch();
      setCreateModal(false);
    } catch (err) {
      setLoading(false);
      setIsERror(true);
      toast.error(err?.data?.message || err?.message || err.error);
    }
  };

  const facilityOptions = [
    "free wifi",
    "swimming pool",
    "Gym",
    "living room",
    "Room Service",
  ];

  return (
    <div className="px-10">
      <div className="flex justify-between items-center  gap-x-2  my-10 ">
        <div className="">
          <h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
            <Link to="/dashboard/index">Home</Link>
            <span class=" text-gray-500 dark:text-gray-300 rtl:-scale-x-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>{" "}
            <Link to="/dashboard/merchant">Merchant Financial Base</Link>
            <span class=" text-gray-500 dark:text-gray-300 rtl:-scale-x-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>{" "}
            Hotels & Apartments
          </h2>
          <Link
            to="/dashboard/merchant"
            className="text-[#4E4E4E] text-sm font-light text-left flex items-center gap-x-2 mt-3"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                stroke="#0C0C0C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                stroke="#0C0C0C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Back
          </Link>
        </div>
      </div>
      <div className="flex justify-between gap-x-2  my-5 ">
        <h2 className="text-[#0C0C0C] font-extrabold text-2xl py-1 text-left  block ">
          Hotels & Apartments
        </h2>

        <div className="relative ">
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              className="w-4 h-4 text-gray-400"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </span>

          <input
            type="text"
            className="w-full py-2 pl-8 pr-4 text-gray-700 bg-[#F9F9F9] border rounded-md placeholder:text-xs  focus:border-blue-400  focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
            placeholder="Search Users"
            autoComplete="off"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <div className="mb-10">
        <div className="flex justify-between items-center  gap-x-2  mb-5 ">
          <div className="">
            <h2 className="text-[#0C0C0C] font-extrabold text-2xl py-1 text-left  block ">
              Accounts
            </h2>
            <h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
              This is a list of all users.
            </h2>
          </div>
          <div className="flex items-center gap-4">
            <h2 className="text-[#0C0C0C] font-medium text-base py-1 text-left  block ">
              Total No users ({user.length})
            </h2>
          </div>
        </div>

        <div className="flex my-3 justify-end h-12">
          <button
            onClick={(e) => {
              e.preventDefault();
              setCreateModal(true);
            }}
            className="text-center transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0F3776]  px-5 py-2 rounded-md "
          >
            Add new Account
          </button>
        </div>

        <section className="container   rounded mx-auto">
          <div className="flex flex-col">
            <div className=" overflow-x-auto  h-[36rem] scrollbar-hide ">
              <div className="inline-block min-w-full py-2 align-middle ">
                <div className="overflow-hidden md:rounded-lg ">
                  <div className="min-w-full ">
                    {isLoading ? (
                      <div className="pb-5">
                        <PageLoading />
                      </div>
                    ) : error ? (
                      <div className="pt-5">
                        <Loading />
                      </div>
                    ) : (
                      <>
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="p-4 text-center text-xs font-bold text-black uppercase tracking-wider"
                              >
                                ACCOUNT NAME
                              </th>

                              <th
                                scope="col"
                                className="p-4 text-left text-xs font-bold text-black uppercase tracking-wider"
                              >
                                ADDRESSS
                              </th>
                              <th
                                scope="col"
                                className="p-4 text-left text-xs font-bold text-black uppercase tracking-wider"
                              >
                                TRANSACTIONS
                              </th>
                            </tr>
                          </thead>

                          <tbody className="bg-white">
                            {filteredAccounts.map((item, i) => (
                              <tr
                                key={item.id}
                                className={
                                  i % 2 === 0 ? "rounded-lg" : "bg-gray-100"
                                }
                              >
                                <td className="flex">
                                  <div className="px-5 text-sm font-medium text-gray-700 bg-[#E7E7E7] whitespace-nowrap">
                                    <div className="py-3 flex flex-wrap content-center justify-center">
                                      <svg
                                        width="35"
                                        height="35"
                                        viewBox="0 0 35 35"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M17.5 0C7.84 0 0 7.84 0 17.5C0 27.16 7.84 35 17.5 35C27.16 35 35 27.16 35 17.5C35 7.84 27.16 0 17.5 0ZM17.5 7C20.8775 7 23.625 9.7475 23.625 13.125C23.625 16.5025 20.8775 19.25 17.5 19.25C14.1225 19.25 11.375 16.5025 11.375 13.125C11.375 9.7475 14.1225 7 17.5 7ZM17.5 31.5C13.9475 31.5 9.7475 30.065 6.755 26.46C9.82031 24.055 13.6038 22.7479 17.5 22.7479C21.3962 22.7479 25.1797 24.055 28.245 26.46C25.2525 30.065 21.0525 31.5 17.5 31.5Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </div>
                                  </div>

                                  <div className="px-3 py-5 text-sm font-bold text-[#111827] whitespace-nowrap ">
                                    {item.hotelMerchantName}
                                  </div>
                                </td>
                                <td className="px-5  text-sm text-gray-500 font-thin  whitespace-nowrap">
                                  {item.address}
                                </td>

                                <td className="px-3 text-sm text-[#fff] whitespace-nowrap ">
                                  <div className="flex items-center justify-between">
                                    <span className="text-gray-500 font-thin">
                                      Total Transactions (
                                      {item.hotel_booking.length})
                                    </span>
                                    <div className="flex items-center justify-end">
                                      <Link
                                        to={`${item.id}/details`}
                                        className="bg-[#0F3776] py-2 px-6 rounded-lg"
                                      >
                                        View Info
                                      </Link>
                                      <div className=" inline-block group ml-2">
                                        <button
                                          type="submit"
                                          className="px-0.5 ml-1 text-xs font-medium text-white   rounded-md focus:outline-none "
                                        >
                                          <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_14_1968)">
                                              <path
                                                d="M7.02002 8.9991C7.02002 9.25923 7.07126 9.51682 7.17081 9.75716C7.27036 9.99749 7.41627 10.2159 7.60021 10.3998C7.78416 10.5838 8.00253 10.7297 8.24286 10.8292C8.4832 10.9288 8.74078 10.98 9.00092 10.98C9.26105 10.98 9.51864 10.9288 9.75898 10.8292C9.99931 10.7297 10.2177 10.5838 10.4016 10.3998C10.5856 10.2159 10.7315 9.99749 10.831 9.75716C10.9306 9.51682 10.9818 9.25923 10.9818 8.9991C10.9817 8.47373 10.7729 7.96993 10.4013 7.59852C10.0297 7.22712 9.52584 7.01853 9.00047 7.01865C8.4751 7.01877 7.9713 7.22758 7.59989 7.59916C7.22849 7.97074 7.0199 8.47463 7.02002 9L7.02002 8.9991ZM7.02002 15.2991C7.02002 15.5592 7.07126 15.8168 7.17081 16.0572C7.27036 16.2975 7.41627 16.5159 7.60021 16.6998C7.78416 16.8838 8.00253 17.0297 8.24286 17.1292C8.4832 17.2288 8.74078 17.28 9.00092 17.28C9.26106 17.28 9.51864 17.2288 9.75898 17.1292C9.99931 17.0297 10.2177 16.8838 10.4016 16.6998C10.5856 16.5159 10.7315 16.2975 10.831 16.0572C10.9306 15.8168 10.9818 15.5592 10.9818 15.2991C10.9817 14.7737 10.7729 14.2699 10.4013 13.8985C10.0297 13.5271 9.52584 13.3185 9.00047 13.3186C8.4751 13.3188 7.9713 13.5276 7.59989 13.8992C7.22849 14.2707 7.0199 14.7737 7.02002 15.2991ZM7.02002 2.6991C7.02002 2.95923 7.07126 3.21682 7.17081 3.45716C7.27036 3.69749 7.41627 3.91586 7.60021 4.09981C7.78415 4.28375 8.00253 4.42966 8.24286 4.52921C8.48319 4.62876 8.74078 4.68 9.00092 4.68C9.26105 4.68 9.51864 4.62876 9.75898 4.52921C9.99931 4.42966 10.2177 4.28375 10.4016 4.09981C10.5856 3.91586 10.7315 3.69749 10.831 3.45716C10.9306 3.21682 10.9818 2.95923 10.9818 2.6991C10.9817 2.17373 10.7729 1.66993 10.4013 1.29852C10.0297 0.927116 9.52584 0.718529 9.00047 0.718649C8.4751 0.718767 7.9713 0.927584 7.59989 1.29916C7.22849 1.67073 7.0199 2.17373 7.02002 2.6991Z"
                                                fill="#B9B9B9"
                                              />
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_14_1968">
                                                <rect
                                                  width="18"
                                                  height="18"
                                                  fill="white"
                                                  transform="translate(0 18) rotate(-90)"
                                                />
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </button>
                                        {/* <!-- Dropdown menu --> */}
                                        <div className="absolute  z-[100] w-32 p-2 hidden group-hover:block right-10 bg-[#FFFFFF] rounded-md shadow-xl ">
                                          <button
                                            onClick={() => {
                                              setUserId(item.id);
                                              setBanModal(true);
                                            }}
                                            className="w-full text-left block px-4 py-2 text-xs text-[#0C0C0C] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
                                          >
                                            Ban User
                                          </button>
                                          <button
                                            onClick={() => {
                                              setUserId(item.id);
                                              setDeleteModal(true);
                                            }}
                                            className="w-full text-left block px-4 py-2 text-xs text-[#CA0D0D] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
                                          >
                                            Delete User
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {banModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold ">
                  Confirm Account Ban
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#141414]/70">
                Are you sure you want to ban this user ?
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setBanModal(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#0F3776] bg-[#D6DDE8]  px-3 py-4 rounded-lg "
                >
                  No, Cancel
                </button>
                <button
                  onClick={() => {
                    const user_id = userId;
                    const banUser = "true";
                    const data = { user_id, banUser };
                    banHandler(data);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
                >
                  Yes, Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {banSucess && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold ">
                  Account Banned Successfully
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#D3B61F]">
                You have successfully Banned this user
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setBanSucess(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0F3776]  px-3 py-4 rounded-lg "
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {unbanModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold ">
                  Confirm Unban Account
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#141414]/70">
                Are you sure you want to Unban this user ?
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setUnbanModal(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#0F3776] bg-[#D6DDE8]  px-3 py-4 rounded-lg "
                >
                  No, Cancel
                </button>
                <button
                  onClick={() => {
                    const user_id = userId;
                    const banUser = "false";
                    const data = { user_id, banUser };
                    unbanHandler(data);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0AB02E]  px-3 py-4 rounded-lg "
                >
                  Yes, Approve
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {unbanSucess && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          B
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold ">
                  Account Unbanned Successfully
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#D3B61F]">
                You have successfully unbanned this user.
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setUnbanSucess(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0F3776]  px-3 py-4 rounded-lg "
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold ">
                  Confirm Account Delete
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#141414]/70">
                Are you sure you want to delete this user ?
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setDeleteModal(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#0F3776] bg-[#D6DDE8]  px-3 py-4 rounded-lg "
                >
                  No, Cancel
                </button>
                <button
                  onClick={() => {
                    const data = userId;
                    deleteHandler(data);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
                >
                  Yes, Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteSucess && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold ">
                  Account Deleted Successfully
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#D3B61F]">
                You have successfully Deleted this user
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setDeleteSucess(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0F3776]  px-3 py-4 rounded-lg "
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {createModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex  items-center">
                <h3 className="text-[#000] text-lg font-semibold">
                  Create New Admin Account
                </h3>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setCreateModal(false);
                  }}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm  ml-auto inline-flex items-center p-2"
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <form onSubmit={handleSubmit(createHandler)}>
                <div>
                  <label
                    for="username"
                    className="text-left block text-sm text-gray-800 font-semibold py-2"
                  >
                    Hotel Name:
                  </label>
                  <input
                    type="text"
                    name="Name"
                    required
                    {...register("hotelMerchantName")}
                    placeholder="Full Name"
                    className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>
                <div>
                  <label
                    for="username"
                    className="text-left block text-sm text-gray-800 font-semibold py-2"
                  >
                    Email:
                  </label>
                  <input
                    type="email"
                    name="email"
                    required
                    {...register("email")}
                    placeholder="Email Address"
                    className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>

                <div className="mt-2">
                  <label
                    for="password"
                    className="text-left block text-sm text-gray-800 font-semibold py-2"
                  >
                    Password:
                  </label>
                  <div className="flex ">
                    <input
                      {...register("password")}
                      required
                      type={passwordType}
                      name="password"
                      id="password"
                      placeholder="Your Password"
                      className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <span className="relative -ml-12 sm:-ml-16 flex items-center px-3 leading-normal text-gray-600 whitespace-no-wrap border-0 rounded rounded-l-none">
                      <button
                        type="button"
                        onClick={() => {
                          if (passwordType === "password") {
                            setPasswordType("text");
                            return;
                          }
                          setPasswordType("password");
                        }}
                        className="flex justify-center w-4 sm:w-full py-1 text-base text-gray-500 transition duration-200 ease-in-out rounded cursor-pointer hover:scale-110 focus:outline-none"
                      >
                        {passwordType === "password" ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_5982_274025)">
                              <path
                                d="M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003M9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003"
                                stroke="#757E7E"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M1 1L23 23"
                                stroke="#757E7E"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_5982_274025">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 6.5C13.8387 6.49389 15.6419 7.00678 17.2021 7.97973C18.7624 8.95267 20.0164 10.3462 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C3.98362 10.3462 5.23763 8.95267 6.79788 7.97973C8.35813 7.00678 10.1613 6.49389 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C12.663 9.5 13.2989 9.76339 13.7678 10.2322C14.2366 10.7011 14.5 11.337 14.5 12C14.5 12.663 14.2366 13.2989 13.7678 13.7678C13.2989 14.2366 12.663 14.5 12 14.5C11.337 14.5 10.7011 14.2366 10.2322 13.7678C9.76339 13.2989 9.5 12.663 9.5 12C9.5 11.337 9.76339 10.7011 10.2322 10.2322C10.7011 9.76339 11.337 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                              fill="#757E7E"
                            />
                          </svg>
                        )}
                      </button>
                    </span>
                  </div>
                </div>

                <div>
                  <label
                    className="text-left block text-sm text-gray-800 font-semibold py-2"
                    htmlFor="type"
                  >
                    Type:
                  </label>
                  <select
                    className="block w-full px-4 py-4 text-gray-700
                    placeholder-gray-400 bg-white border border-gray-200
                    rounded-lg focus:border-blue-400 focus:ring-blue-400
                    focus:outline-none focus:ring focus:ring-opacity-10"
                    id="type"
                    {...register("type", { required: true })}
                  >
                    <option value="">Select Type</option>
                    <option value="Hotel">Hotel</option>
                    <option value="Apartment">Apartment</option>
                    <option value="Hostel">Hostel</option>
                  </select>
                </div>

                <div>
                  <label
                    for="address"
                    className="text-left block text-sm text-gray-800 font-semibold py-2"
                  >
                    Address:
                  </label>
                  <input
                    type="text"
                    name="address"
                    required
                    {...register("address")}
                    placeholder="Hotel Address"
                    className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>

                <MultiSelect
                  options={facilityOptions}
                  control={control}
                  name="facilities"
                />

                <div className="mt-3">
                  <button
                    type="submit"
                    disabled={loading}
                    className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#284C84] rounded-sm hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                  >
                    {loading ? <Spinner /> : "Create New Merchant"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Hotels;
